<template>
  <div class="flex items-center gap-x-6">
    <span
      class="scrubber__current pt-1 w-14 text-label-big text-white opacity-[0.65]"
    >
      {{ store.playerStore.elapsedTimeLabel }}
    </span>
    <input
      min="0"
      :max="store.playerStore.totalDuration"
      :value="store.playerStore.player.currentTime"
      type="range"
      class="flex-1 w-full styled-slider slider-progress"
      step="0.01"
      @input="handleDrag"
      ref="progressBar"
    />
    <span
      class="scrubber__total pt-1 text-end text-label-big text-white opacity-[0.65]"
    >
      {{ store.playerStore.totalTimeLabel }}
    </span>
  </div>
</template>

<script setup>
import { watch } from "vue";
import { usePlayerUi } from "@/stores/playerUi";
import { useWatchStore } from "@/stores/watch";
const { secondsToTime } = useDateTime();

const watchStore = useWatchStore();
const store = usePlayerUi();
const progressBar = ref();
const { videoInterations } = useADtvGTM();
const handleDrag = (e) => {
  store.playerStore.player.currentTime = e.target.value;
  store?.playerStore?.player?.play();
  setValues(e.target.value, store?.playerStore?.totalDuration);

  const jumpTip = `jump: ${secondsToTime(e.target.value)}`;
  videoInterations(jumpTip, {
    media_name: watchStore.currentEpisode?.tvShowReference?.seriesName,
    season: watchStore.currentEpisode?.tvShowReference?.seasonNumber,
    episode: watchStore.currentEpisode?.tvShowReference?.episodeNumber,
    show_id: watchStore.currentEpisode?.tvShowReference?.seriesExternalId,
    season_id: watchStore.currentEpisode?.tvShowReference?.seasonExternalId,
    episode_id: watchStore?.currentEpisode?.assetExternalId,
  });
};

onMounted(() => {
  setValues(0, 100);
});
watch(
  () => store?.playerStore?.currentTime,
  (newVal) => setValues(newVal, store?.playerStore?.totalDuration)
);

const setValues = (val, max) => {
  progressBar.value.style.setProperty("--value", val);
  progressBar.value.style.setProperty("--min", 0);
  progressBar.value.style.setProperty("--max", max);
};
</script>

<style lang="scss" scoped>
/**
  Variables
*/

$height: 1rem;
$bar-height: 0.5rem;
$circle-size: 1.5rem;
$bgColor: rgba(255, 255, 255, 0.4);
$accentColor: theme("colors.brand-red.DEFAULT");

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type="range"].styled-slider {
  height: $height;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  accent-color: $accentColor;
}

/*progress support*/
input[type="range"].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

input[type="range"].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type="range"].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: $circle-size;
  height: $circle-size;
  border-radius: $circle-size;
  background: $accentColor;
  border: none;
  box-shadow: none;
  margin-top: calc(max(($height - 4px) * 0.7, 0px) - 2 * $height * 0.5);
}

input[type="range"].styled-slider::-webkit-slider-runnable-track {
  height: $bar-height;
  border-radius: $bar-height;
  background: $bgColor;
  box-shadow: none;
}

input[type="range"].styled-slider::-webkit-slider-thumb:hover {
  background: $accentColor;
}

input[type="range"].styled-slider:hover::-webkit-slider-runnable-track {
  background: $bgColor;
}

input[type="range"].styled-slider::-webkit-slider-thumb:active {
  background: $accentColor;
}

input[type="range"].styled-slider:active::-webkit-slider-runnable-track {
  background: $bgColor;
}

input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

input[type="range"].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

input[type="range"].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

/*mozilla*/
input[type="range"].styled-slider::-moz-range-thumb {
  width: $circle-size;
  height: $circle-size;
  border-radius: $circle-size;
  background: $accentColor;
  border: none;
  box-shadow: none;
}

input[type="range"].styled-slider::-moz-range-track {
  height: $bar-height;
  border-radius: $bar-height;
  background: $bgColor;
  box-shadow: none;
}

input[type="range"].styled-slider::-moz-range-thumb:hover {
  background: $accentColor;
}

input[type="range"].styled-slider:hover::-moz-range-track {
  background: $bgColor;
}

input[type="range"].styled-slider::-moz-range-thumb:active {
  background: $accentColor;
}

input[type="range"].styled-slider:active::-moz-range-track {
  background: $bgColor;
}

input[type="range"].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

input[type="range"].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

input[type="range"].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient($accentColor, $accentColor) 0 / var(--sx) 100%
      no-repeat,
    $bgColor;
}

/*ms*/
input[type="range"].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-thumb {
  width: $circle-size;
  height: $circle-size;
  border-radius: $circle-size;
  background: $accentColor;
  border: none;
  box-shadow: none;
  margin-top: 0;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-track {
  height: $bar-height;
  border-radius: $bar-height;
  background: $bgColor;
  box-shadow: none;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-thumb:hover {
  background: $accentColor;
}

input[type="range"].styled-slider:hover::-ms-track {
  background: $bgColor;
}

input[type="range"].styled-slider::-ms-thumb:active {
  background: $accentColor;
}

input[type="range"].styled-slider:active::-ms-track {
  background: $bgColor;
}

input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
  height: max(calc($bar-height - 1px - 1px), 0px);
  border-radius: $bar-height;
  margin: -1px 0 -1px -1px;
  background: $accentColor;
}

input[type="range"].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: $accentColor;
}

input[type="range"].styled-slider.slider-progress:active::-ms-fill-lower {
  background: $accentColor;
}
</style>
