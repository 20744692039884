<template>
  <div>
    <template v-if="store.playerId == PLAYERS.SONG_PLAYER">
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'playing'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPause"
        class="fill-white"
      ></ButtonsIcon>
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'paused'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPlay"
        class="fill-white"
      ></ButtonsIcon>
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'ended'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconReplay"
        class="fill-white"
      ></ButtonsIcon>
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'buffering'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconSpinner"
        class="fill-white animate-spin"
      ></ButtonsIcon>
    </template>
    <template v-else-if="store.playerId == PLAYERS.LIVE_PLAYER">
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'playing'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconStopSquare"
        class="fill-white"
      ></ButtonsIcon>
      <ButtonsIcon
        v-else-if="store.playerStore.playbackState == 'buffering'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconSpinner"
        class="fill-white animate-spin"
      ></ButtonsIcon>
      <ButtonsIcon
        v-else
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPlay"
        class="fill-white"
      ></ButtonsIcon>
    </template>
    <template v-else>
      <ButtonsIcon
        v-if="store.playerStore.playbackState == 'playing'"
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPause"
        class="fill-white"
      ></ButtonsIcon>
      <ButtonsIcon
        v-else
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPlay"
        class="fill-white"
      ></ButtonsIcon>
    </template>
  </div>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
import { PLAYERS } from "@/constants";

const store = usePlayerUi();
</script>

<style lang="scss" scoped></style>
