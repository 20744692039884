<template>
  <div>
    <tippy
      :arrow="false"
      theme="blur"
      interactive
      :onShown="useHideTippyOnChildrenClick"
      trigger="mouseenter focus click"
    >
      <ButtonsIcon
        :enable-hover="false"
        :breakpoints="store.iconBreakpoints"
        iconName="IconPlaybackSpeed"
      ></ButtonsIcon>
      <template #content>
        <TippyMenu>
          <div class="w-full flex flex-col">
            <span
              class="bg-white/10 text-body-big text-white opacity-[0.87] text-center px-6 py-4"
            >
              {{ $t("Speed") }}
            </span>
            <div
              :key="key"
              v-for="(s, key) in speeds"
              class="px-6 py-3 flex justify-center items-center gap-x-4 cursor-pointer"
              @click="store.playerStore.setSpeed(s.value)"
              :class="[
                { 'bg-white/30': store.playerStore.getSpeed == s.value },
              ]"
            >
              <span class="text-body-big text-center text-white opacity-[0.87]">
                {{ s.label }}
              </span>
            </div>
          </div>
        </TippyMenu>
      </template>
    </tippy>
  </div>
</template>

<script setup>
import { usePlayerUi } from "@/stores/playerUi";
const store = usePlayerUi();

const speeds = computed(() => {
  return [
    {
      label: "2x",
      value: 2.0,
    },
    {
      label: "1.5x",
      value: 1.5,
    },
    {
      label: "1x",
      value: 1.0,
    },
    {
      label: "0.5x",
      value: 0.5,
    },
  ];
});
</script>

<style lang="scss" scoped></style>
